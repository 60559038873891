<template>
	<div class="q-my-sm">
		<strong>{{field.label}}</strong>
		<div
			v-for="option in field.options"
			:key="option.id"
		>
			<div v-if="on_call_settings.options_staffing[option.id]">
				<on-call-field-option :option="option" />
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import OnCallFieldOption from '@/components/datasets/edit/on_call/OnCallFieldOption.vue'

export default {
	name: 'OnCallInputFields',
	components: {
		OnCallFieldOption,
	},
	props: {
		field: Object,
	},
	computed: {
		...mapGetters(['on_call_settings']),
	},
}
</script>

