<template>
	<div v-show="logged_user.customer_permission == 'ADMIN' && customer_group_permissions.on_call_period">
		<h1 class="setting-title">
			On call configuration: {{ cg.name }}
		</h1>
		<div class="settings-wrapper">
			<q-select
				v-model="week_start_day"
				hint="Start day of working week"
				label="Day of week"
				class="q-my-sm"
				:options="days_list"
				:error="week_start_day_error"
				:error-message="week_start_day_error_message"
			/>
		</div>
		<div class="settings-wrapper">
			<q-input
				outlined
				v-model="year_start_date"
				hint="Start day of working year. Format DD/MM/YYYY"
				label="Day of year"
				class="q-my-sm"
				:error="year_start_date_error"
				:error_message="year_start_date_error_message"
				@click="date_dialog=true"
			>
				<template v-slot:append>
					<q-btn
						flat
						icon="calendar_today"
						class="cursor-pointer date-btn"
						@click="date_dialog=true"
					/>
				</template>
			</q-input>
			<q-dialog v-model="date_dialog">
				<q-date
					v-model="year_start_date"
					mask="DD/MM/YYYY"
					:navigation-min-year-month="`${current_year}/01`"
					:navigation-max-year-month="`${current_year}/12`"
				>
					<div class="row items-center justify-end">
						<q-btn v-close-popup label="Close" color="primary" flat />
					</div>
				</q-date>
			</q-dialog>
		</div>
		<div
			class="settings-wrapper"
			v-show="logged_user.customer_permission == 'ADMIN' && current_CG.root_customer_group_permissions.includes('Start time of the working day')"
		>
			<q-input
				outlined
				v-model="day_start_time"
				hint="Start time of the working day. Format HH:MM"
				label="Time"
				class="q-my-sm"
				:error="day_start_time_error"
				:error_msg='day_start_time_error_message'
				@click="time_dialog=true"
			>
				<template v-slot:append>
					<q-btn flat icon="access_time" class="cursor-pointer date-btn" @click="time_dialog=true"/>
				</template>
			</q-input>
			<q-dialog v-model="time_dialog">
				<q-time v-model="day_start_time" format24h>
					<div class="row items-center justify-end">
						<q-btn v-close-popup label="Close" color="primary" flat />
					</div>
				</q-time>
			</q-dialog>
		</div>
		<div
			v-for="field in on_call_data_fields"
			:key="field.id"
		>
			<div
				v-if="(field.field_type == 'SINGLESELECT' || field.field_type == 'MULTISELECT') &&
					customer_group_permissions.minimum_staffing_requirements &&
					field.all_options_disabled == false &&
					field.reports_only_field == false"
				class="settings-wrapper"
			>
				<on-call-input-fields :field="field" />

			</div>
		</div>
		<q-fab
			class="flex justify-end "
			style="position: sticky; bottom: 50px; z-index: 1"
			:label="$q.screen.gt.sm ? 'Actions' : ''"
			label-position="left"
			color="primary"
			icon="keyboard_arrow_up"
			direction="up"
		>
			<q-fab-action
				color="primary"
				icon="contact_phone"
				:label="$q.screen.gt.sm ? 'Check on call recipients' : ''"
				@click="checkCurrentRecipients"
			>
				<q-tooltip v-if="!$q.screen.gt.sm" anchor="top middle" :delay="500">
					Check on call recipients
				</q-tooltip>
			</q-fab-action>
			<q-fab-action
				color="primary"
				icon="download"
				:label="$q.screen.gt.sm ? 'Export' : ''"
				@click="open_export_dialog = true"
			>
				<q-tooltip v-if="!$q.screen.gt.sm" anchor="top middle" :delay="500">
					Export
				</q-tooltip>
			</q-fab-action>
			<q-fab-action
				color="primary"
				icon="save"
				:label="$q.screen.gt.sm ? 'Save' : ''"
				@click="buttonHandler"
			>
				<q-tooltip v-if="!$q.screen.gt.sm" anchor="top middle" :delay="500">
					Save
				</q-tooltip>
			</q-fab-action>
		</q-fab>
		<q-dialog v-model="open_export_dialog">
			<q-card class="q-px-md" style="width: 700px; max-width: 80vw">
				<q-card-section>
					<div class="text-h6">Export on call period</div>
				</q-card-section>
				<q-card-section class="q-py-sm">
					<q-input
						outlined
						v-model="start_date"
						label="Export From"
						hint="Format DD/MM/YYYY"
						@click="export_from_date_dialog=true"
						:error="export_start_date_error"
						:error-message="export_start_date_error_message"
					>
						<template v-slot:append>
							<q-btn
								flat
								icon="calendar_today"
								class="cursor-pointer date-btn"
								@click="export_from_date_dialog=true"
							/>
						</template>
					</q-input>
					<q-dialog v-model="export_from_date_dialog">
						<q-date
							v-model="start_date"
						>
							<div class="row items-center justify-end">
								<q-btn v-close-popup label="Close" color="primary" flat />
							</div>
						</q-date>
					</q-dialog>
					<q-input
						outlined
						v-model="end_date"
						label="Export To"
						hint="Format DD/MM/YYYY"
						@click="export_to_date_dialog=true"
						:error="export_end_date_error"
						:error-message="export_end_date_error_message"
					>
						<template v-slot:append>
							<q-btn
								flat
								icon="calendar_today"
								class="cursor-pointer date-btn"
								@click="export_to_date_dialog=true"
							/>
						</template>
					</q-input>
					<q-dialog v-model="export_to_date_dialog">
						<q-date
							v-model="end_date"
							:options="date => date > start_date"
						>
							<div class="row items-center justify-end">
								<q-btn v-close-popup label="Close" color="primary" flat />
							</div>
						</q-date>
					</q-dialog>
				</q-card-section>
				<q-card-section class="q-pt-none">
					<div>
						<q-btn
							color="primary"
							class="full-width"
							label="Export"
							@click="dialogBtnClickHandler"
						/>
					</div>
				</q-card-section>
			</q-card>
		</q-dialog>
		<q-dialog v-model="show_on_call_recipients">
			<q-card>
				<q-card-section class="q-pb-sm">
					<q-table
						:data="recipient_data.length ? recipient_data : []"
						:rows-per-page-options="[5, 10, 20, 50]"
						binary-state-sort
						:columns="columns"
						no-data-label="There are no users currently on call"
						no-results-label="There are no users currently on call"
					/>
				</q-card-section>
				<q-card-actions class="justify-end q-pb-sm">
					<q-btn flat label="Close" color="primary" @click="show_on_call_recipients=false" />
				</q-card-actions>
			</q-card>
		</q-dialog>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import OnCallInputFields from '@/components/datasets/edit/on_call/OnCallInputFields.vue'
import DatasetsAPI from '@/services/api/Datasets.js'
import SaveButton from '@/components/datasets/utils/SaveButton.vue'
import moment from 'moment-timezone'

export default {
	name: 'OnCall',
	components: {
		OnCallInputFields,
		SaveButton,
	},
	data() {
		return {
			export_from_date_dialog: false,
			export_to_date_dialog: false,
			date_dialog: false,
			time_dialog: false,
			current_year: null,
			open_export_dialog: false,
			start_date: '',
			end_date: '',
			show_on_call_recipients: false,
			days_list: [
				{
					label: 'Monday',
					value: 'Monday',
				},
				{
					label: 'Tuesday',
					value: 'Tuesday',
				},
				{
					label: 'Wednesday',
					value: 'Wednesday',
				},
				{
					label: 'Thursday',
					value: 'Thursday',
				},
				{
					label: 'Friday',
					value: 'Friday',
				},
				{
					label: 'Saturday',
					value: 'Saturday',
				},
				{
					label: 'Sunday',
					value: 'Sunday',
				},
			],
			export_start_date_error: false,
			export_end_date_error: false,
			export_start_date_error_message: '',
			export_end_date_error_message: '',
			year_start_date_error_message: '',
			week_start_day_error_message: '',
			day_start_time_error_message: '',
			recipient_data: [],
			columns: [
				{
					name: 'user',
					field: row => row.user,
					align: 'left',
					label: 'User',
					sortable: true,
				},
				{
					name: 'cg_name',
					field: row => row.cg_name,
					align: 'left',
					label: 'Database name',
					sortable: true,
				},
				{
					name: 'start_date',
					field: obj => {
						return this.formatDate(obj.start_date)
					},
					align: 'left',
					label: 'Start Date',
					sortable: true,
				},
				{
					name: 'end_date',
					field: obj => {
						return this.formatDate(obj.end_date)
					},
					align: 'left',
					label: 'End Date',
					sortable: true,
				},
			]
		}
	},
	computed: {
		...mapGetters([
			'on_call_settings',
			'on_call_data_fields',
			'customer_group_permissions',
			'logged_user',
			'current_CG',
		]),
		week_start_day: {
			get() {
				return this.$store.getters.on_call_settings.on_call_settings
					.week_start_day
			},
			set(val) {
				this.updateOnCallSettings({ attr: 'week_start_day', val: val.value })
			},
		},
		year_start_date: {
			get() {
				if (
					this.$store.getters.on_call_settings.on_call_settings.year_start_date
				) {
					return (
						this.$store.getters.on_call_settings.on_call_settings
							.year_start_date + '/' + this.current_year
					)
				}
			},
			set(val) {
				this.updateOnCallSettings({
					attr: 'year_start_date',
					val: val.substring(0, val.length - 5),
				})
			},
		},
		day_start_time: {
			get() {
				return this.$store.getters.on_call_settings.on_call_settings
					.day_start_time
			},
			set(val) {
				this.updateOnCallSettings({ attr: 'day_start_time', val: val })
			},
		},
		year_start_date_error() {
			return this.year_start_date_error_message ? true : false
		},
		week_start_day_error() {
			return this.week_start_day_error_message ? true : false
		},
		day_start_time_error() {
			return this.day_start_time_error_message ? true : false
		},
		cg() {
			return this.$store.getters.customer_groups.filter(
				(cg) => cg.id == this.$route.params.id
			)[0]
		},
	},
	methods: {
		...mapActions([
			'saveOnCallSettings',
			'updateOnCallSettings',
			'fetchUser',
		]),
		async buttonHandler() {
			const response = await this.saveOnCallSettings(this.$route.params.id)
			this.year_start_date_error_message = ''
			this.week_start_day_error_message = ''
			this.day_start_time_error_message = ''
			if (response.error) {
				let error_message = response.error_message
				if (response.error_message.includes('day of working year')) {
					this.year_start_date_error_message = response.error_message
				} else if (response.error_message.includes('day of working week')) {
					this.week_start_day_error_message = response.error_message
				} else if (response.error_message.includes('time of the working day')) {
					this.day_start_time_error_message = response.error_message
				}
				this.$q.notify({
					timeout: 6700,
					message: error_message,
					actions: [
						{
							label: 'Dismiss',
							color: 'primary',
						},
					],
				})
				return
			}
			this.$q.notify(response)
			await this.fetchUser()
		},
		handleErrorMessage(error_message) {
			this.$q.notify({
				timeout: 6700,
				message: error_message,
				actions: [
					{
						label: 'Dismiss',
						color: 'primary',
					},
				],
			})
		},
		async dialogBtnClickHandler() {
			this.export_start_date_error = false
			this.export_start_date_error_message = ''
			this.export_end_date_error = false
			this.export_end_date_error_message = ''
			if (this.start_date == null || this.start_date == '') {
				this.export_start_date_error = true
				this.export_start_date_error_message =
					'You have to enter start period for the export'
			}
			if (this.end_date == null || this.end_date == '') {
				this.export_end_date_error = true
				this.export_end_date_error_message =
					'You have to enter end period for the export'
			}
			if (
				this.export_start_date_error_message ||
				this.export_end_date_error_message
			) {
				return
			}
			let data = {}
			data.export_start_date = this.start_date
			data.export_end_date = this.end_date
			data.export_customer_group_id = this.$route.params.id

			let response = await DatasetsAPI.exportOnCallPeriods(data)
			if (response.error) {
				this.handleErrorMessage(response.error_message)
				return
			}else{
				this.open_export_dialog = false
				let message = 'Your on call settings export was scheduled with ID: ' + response['file_id'] +
					'. You will receive a message with the file on ' + this.logged_user.email + '.';
				this.$q.notify({
					timeout: 6700,
					message: message,
					actions: [
						{
							label: 'Dismiss',
							color: 'primary',
						},
					],
				})
			}
		},
		async checkCurrentRecipients() {
			this.$q.loading.show({
				delay: 400,
			})
			let response = await DatasetsAPI.getOnCallRecipients(this.$route.params.id)
			if (response.error) {
				this.handleErrorMessage(response.error_message)
				return
			}else{
				this.recipient_data = response
			}
			this.show_on_call_recipients = true
			this.$q.loading.hide()
		},
		formatDate(date){
			let formatted_date = moment.tz(date, "YYYY-MM-DDTHH:mm:ss", "UTC");
			formatted_date = formatted_date.clone().tz(this.logged_user.preferred_timezone)
			return formatted_date.format('DD/MM/YYYY HH:mm');
		},
	},
	created() {
		this.current_year = new Date().getFullYear()
		if (this.year_start_date){
			this.year_start_date = this.year_start_date.replace('-', '/')
		}
	},
}
</script>
<style lang="scss">
	.date-btn .q-btn__wrapper {
		padding: 0.5rem;
	}
</style>