import { render, staticRenderFns } from "./OnCall.vue?vue&type=template&id=bce220c2&"
import script from "./OnCall.vue?vue&type=script&lang=js&"
export * from "./OnCall.vue?vue&type=script&lang=js&"
import style0 from "./OnCall.vue?vue&type=style&index=0&id=bce220c2&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QSelect from 'quasar/src/components/select/QSelect.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QDate from 'quasar/src/components/date/QDate.js';
import QTime from 'quasar/src/components/time/QTime.js';
import QFab from 'quasar/src/components/fab/QFab.js';
import QFabAction from 'quasar/src/components/fab/QFabAction.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QTable from 'quasar/src/components/table/QTable.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QSelect,QInput,QBtn,QDialog,QDate,QTime,QFab,QFabAction,QTooltip,QCard,QCardSection,QTable,QCardActions});qInstall(component, 'directives', {ClosePopup});
