<template>
	<div class="on-call-field-option">
		<q-input
			v-model="staffing_value"
			outlined
			type="number"
			label="Minimum Staffing Requirements"
			name="option"
			:hint="`Option label: ${option.value}`"
			onkeydown="return event.keyCode !== 69 && event.keyCode !== 189 && event.keyCode !== 190"
		/>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
	name: 'OnCallFieldOption',
	props: {
		option: Object,
	},
	computed: {
		...mapGetters(['on_call_settings']),
		staffing_value: {
			get() {
				return this.$store.getters.on_call_settings.options_staffing[
					this.option.id
				].staffing
			},
			set(val) {
				if (val !== '') {
					val = val < 0 ? 0 : val
					this.updateOptionsStaffing({
						option_id: this.option.id,
						val: parseInt(val),
					})
				}
			},
		},
	},
	methods: {
		...mapActions(['updateOptionsStaffing']),
	},
}
</script>

<style lang="scss" scoped>
.on-call-field-option {
	margin-top: 1rem;
	padding-left: 1rem;
}
</style>
